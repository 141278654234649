<template>
    <div>
        <v-toolbar flat>
        <v-icon left>mdi-account-box-outline</v-icon>
        <v-toolbar-title>CONTACTOS WA1</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <!-- <v-btn @click="abrirDialogDevice(null)" color="blue" small dark
            ><v-icon>mdi-plus</v-icon> Crear</v-btn
        > -->
        <v-col cols="3">
            <v-select label="Dispositivo"
            class="mt-4"
            v-model="dispId"
            :items="dispositivos"
            item-text="description"
            item-value="device_id"
            @change="cargarContactos()"
            >
            </v-select>
        </v-col>
        <v-col cols="3" >
            <v-text-field v-model="busContacto" class="mt-4" label="contacto"  @keyup.enter="cargarContactos()"></v-text-field>
        </v-col>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :itemsPerPage="itemsPerPage"
            :items="contactos"
            class="elevation-1"
            small            
            item-key="contact_id"
            hide-default-footer
        >
            <template v-slot:item.opciones="{ item }">
                <v-btn small text @click="editarContacto(item)" color="blue" small dark
                ><v-icon small color="green">mdi-pencil</v-icon></v-btn
                >
            
            </template>
        

            <template v-slot:footer>        
                <v-pagination
                class="mt-10"
                v-model="currentPage"
                :length="pageCount"
                @input="handlePageChange"
                total-visible="10"
                ></v-pagination>
            </template>
        </v-data-table>

        <!-- DIALOGO CONTACTO -->
        <v-dialog v-model="dialogoContacto" persistent width="900" scrollable >
            
                <v-card class="ma-0 pa-0" >

                    <v-toolbar dark color="blue" height="40" flat  fixed >
                        <v-icon left>mdi-account-box-outline</v-icon>
                        Contacto
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dialogoContacto = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                    <v-row class="mt-1  pt-0 mt-0 mx-0">
                        <v-col md="6" class="py-0 my-0 px-4">
                            <v-text-field
                            :rules="requiredRule"
                            v-model="datosContacto.contact_number"
                            label="Número"
                            ></v-text-field>
                        </v-col>

                        <v-col md="6" class="py-0 my-0 px-4">
                            <v-text-field
                            :rules="requiredRule"
                            v-model="datosContacto.contacto_nombre"
                            label="Nombre"
                        
                            ></v-text-field>
                        </v-col>                    
                    </v-row>            
                    <v-row>
                        <v-col>
                            <v-data-table
                                :items="comEvents"
                                :headers="headComEv"
                                :itemsPerPage=itemsPerPage
                            >
                            <template v-slot:item.num_origen={item}>
                                <span v-if="item.num_origen == datosContacto.contact_number">
                                 <v-icon>mdi-arrow-bottom-right-thick </v-icon>
                                </span>

                                <span v-if="item.num_origen != datosContacto.contact_number">
                                    <v-icon>mdi-arrow-top-left-thick </v-icon>
                                </span>
                                
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                    <v-row class="mt-1 mx-0 pt-0 mt-0 mx-0">
                        <v-col class="mx-4 my-2">                            
                            <v-textarea rows="2" v-model="datosMensaje.mensaje" label="Mensaje"></v-textarea>                        
                        </v-col>
                    </v-row>
                   
                    <v-toolbar fixed>
                        <v-btn color="primary" small @click="enviarMensaje()">
                            <v-icon>mdi-send</v-icon>Enviar
                        </v-btn>
                        <v-divider vertical class="px-2" inset></v-divider>
                        <v-btn text small @click="dialogoContacto = false">
                            <v-icon>mdi-content-cancel</v-icon>Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small @click="cargarMensajes()" icon><v-icon>mdi-reload</v-icon></v-btn>
                    </v-toolbar>
                </v-card>
            
        </v-dialog>
    </div>
    </template>
   
    <script>
    var conn = new ab.Session('ws://localhost:8081',
        function() {
            conn.subscribe('kittensCategory', function(topic, data) {
                // This is where you would add the new article to the DOM (beyond the scope of this tutorial)
                console.log('New article published to category "' + topic + '" : ' + data.title);
            });
        },
        function() {
            console.warn('WebSocket connection closed');
        },
        {'skipSubprotocolCheck': true}
    );

    import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
    import Vue from 'vue';
    import "../../assets/autobahn"
    export default {
    name: "ContactosWa",
    data: () => ({
        

        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 50,
        pageCount: 0,
        dispId: "",
        required: [(v) => !!v || "El campo es requerido"],
        headers: [
            { text: "Opciones", align: "start", value: "opciones" },
            { text: "ID", align: "start", value: "contact_id" },
            { text: "Nombre", align: "start", value: "contacto_nombre" },
            { text: "Número", align: "start", value: "num_telefono" },
            
        ],
                
        dialogoContacto: false,
        datosContacto: {
            contacto_id: "",
            full_name: "",
            push_name: "",
            contact_number:""
        },
        crearContacto: false,
        contactos: [],
        dispositivos: [],
        datosMensaje: {
            device_id: "",
            mensaje:"",
            destino: ""
        },
        headComEv: [
            
            { text: "ID", align: "start", value: "communication_event_id" },                     
            { text: "Tipo", align: "start", value: "message_type" },            
            { text: "Fecha", align: "start", value: "fecha" },            
            { text: "Hora", align: "start", value: "hora" },              
            { text: "Media", align: "start", value: "media_type" },
            { text: "Orig", align: "start", value: "num_origen" },
            { text: "Mensaje", align: "start", value: "content" },
            
            
        ],

        comEvents: [],
        busContacto: ""
    }),
    computed: {
        ...mapState("master", ["loadingBtn", "companies"]),
        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
        ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),



        cargarDatos() {
           // this.setLoadingTable(true);

            this.setUrl("wa-contactos");

            this.requestApi({
                method: "POST",
                data: {
                    accion: "cargarDatos",
                    
                },
            }).then((res) => {
                    //console.log(res.data);
                    this.dispositivos = res.data.detail.dispositivos
                })
                .then(() => {
                   // this.setLoadingTable(false);
                });
        },

        cargarContactos() {           
            this.setUrl("wa-contactos");
            this.requestApi({
                method: "GET",
                data: {
                    page: this.currentPage,
                    page_size: this.itemsPerPage,
                    paginator: true,
                    dispId: this.dispId,
                    nombre: this.busContacto
                },
            }).then((res) => {
                    //console.log(res.data._embedded.wa_contactos);
                    this.contactos = res.data._embedded.wa_contactos;
                    this.pageCount = res.data.page_count;                    
                    this.totalItems = res.data.total_items;
                })
                .then(() => {
                   
                });
        },
        abrirDialogContacto(contactoId) {
            
            this.dialogoContacto = true;
            this.datosContacto = {
                contacto_id: "",
                push_name: "",                
            };

            
            this.crearContacto = true;
            if (contactoId != null) {
                this.crearContacto = false;
                this.cargarContactos(contactoId);
            }
        },

        editarContacto(item) {
            this.dialogoContacto = true;            
            this.crearContacto = false;

            this.datosContacto  = 
                {
                    contacto_id: item.contacto_id,
                    contacto_nombre: item.contacto_nombre,
                    contact_number: item.contact_number                    
                };

             this.datosMensaje = {
                    device_id: item.device_id,
                    destino: item.num_telefono
             }
             this.crearContacto = false;
             this.cargarMensajes()

                
            
        },

        guardarContacto() {
            if(!this.$refs.formDevice.validate()) {
                return false
            }
            this.setLoadingTable(true);
            this.setUrl("com-device");
            this.requestApi({
            method: "POST",
            data: {
                accion: this.crearDevice == true ? "crearDevice" : "modificarDevice",
                device: this.datosDevice,
            },
            })
            .then((res) => {
                console.log(res.data);
                this.cargarContactos();
                this.dialogoDataSource = false;
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        
        },
        borrarContacto(devId) {

        Vue.swal({
                    html: "Está seguro de eliminar esta fuente ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then( result => {

            this.setLoadingTable(true);
            this.setUrl("com-device");
            this.requestApi({
                method: "DELETE",
                data: {
                accion: "borrarDevice",
                device_id: devId, // this.selFuentes[0].data_source_id,
                },
            })
                .then((res) => {
                //console.log(res.data);
                this.cargarDatos();
                this.dialogoDevice = false;
                })
                .then(() => {
                this.setLoadingTable(false);
                });
            });
        },
        enviarMensaje() {
            this.setLoadingTable(true);
            this.setUrl("wa-contactos");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "enviarMensaje",
                    device_id: this.datosMensaje.device_id,
                    mensaje: this.datosMensaje.mensaje,
                    destino: this.datosMensaje.destino                    
                },
            }).then((res) => {
                //console.log(res.data);
                //this.cargarDatos();
                this.dialogoDevice = false;
                })
                .then(() => {
                this.setLoadingTable(false);
             });
            
        },
        cargarMensajes(){
            this.setLoadingTable(true);
            this.setUrl("wa-contactos");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "cargarMensajes",
                    device_id: this.datosMensaje.device_id,                    
                    contactNumber: this.datosMensaje.destino,
                    page: this.currentPage 
                },
            }).then((res) => {
                //console.log(res.data);
                this.comEvents = res.data.detail;
                this.dialogoDevice = false;
                })
                .then(() => {
                this.setLoadingTable(false);
             });
        },
        handlePageChange(value) {
            this.cargarContactos();
        },
    },
    mounted() {
        this.cargarDatos();
        this.cargarContactos();
        this.setTitleToolbar("CONTACTOS WA");
    },
    };


</script>
